<template>
  <div class="processManage">
    <navi-gation />
    <div v-if="show == 0">
      <el-row :gutter="3" style="padding: 10px 0 10px 10px">
        <!-- 时间围栏 -->

        <el-col :span="3">
          <el-input
            v-model="queryObj.name"
            size="small"
            placeholder="请输入名称"
            style="width: 100%"
            @keyup.enter.native="searchList"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button size="small" type="primary" @click="searchList"
            >查询</el-button
          >
        </el-col>
        <el-col :span="24" style="margin-top: 10px">
          <el-button-group>
            <el-button type="primary" size="small" @click="show = 1">{{
              $t("public.New")
            }}</el-button>
            <el-button
              @click="deletes"
              :type="typeInfo"
              :disabled="!hasSeleted"
              size="small"
              >{{ $t("public.Delete") }}</el-button
            >
            <!-- <el-button style="display: none" type="primary" size="small">删除</el-button> -->
          </el-button-group>
        </el-col>
      </el-row>
      <el-row
        class="formBox"
        :gutter="16"
        style="padding: 0px 10px; margin-left: 0px; margin-right: 0px"
      >
        <!-- <div
          @click="maskLayer"
          class="callenia"
          style="
            width: 55px;
            height: 0px;
            background: rgba(255,  255,  255, 0.1);
            position: absolute;
            top: 10px;
            left: 11px;
            z-index: 2;
            font-size: 12px;
          "
        >
          <i
            class="el-icon-caret-bottom"
            style="
              position: absolute;
              top: 2px;
              left: 34px;
              color: #969696;
              font-size: 14px;
            "
          ></i>
        </div> -->
        <el-table
          ref="multipleTable"
          :row-key="getRowKeys"
          :data="dictData"
          stripe
          size="mini"
          tooltip-effect="dark"
          class="tableClass"
          :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }"
          @sort-change="sort"
          @selection-change="handleSelectionChange"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            align="center"
            :reserve-selection="true"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('DeviceManage.TimeFence.Name')"
            width="120"
            sortable="custom"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('public.Section')"
            width="120"
            sortable="custom"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{
                row.groupFullName.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="timezone"
            :label="$t('DeviceManage.TimeFence.TimeZone')"
            width="120"
            sortable="custom"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="valid"
            :label="$t('DeviceManage.TimeFence.TimeFrame')"
            width="120"
            sortable="custom"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.valid == 1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.start + '~' + row.end"
                  placement="top"
                >
                  <el-link style="font-size: 12px" :underline="false">{{
                    $t("DeviceManage.TimeFence.Custom")
                  }}</el-link>
                </el-tooltip>
              </template>
              <template v-if="row.valid == 0">
                {{ $t("DeviceManage.TimeFence.PermanentlyValid") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('DeviceManage.TimeFence.CycleTime')"
            width="120"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.weekdays.split(',').indexOf('1') != -1">
                {{ $t("DeviceManage.TimeFence.weeks.mon") }}
              </template>
              <template v-if="row.weekdays.split(',').indexOf('2') != -1">
                {{ $t("DeviceManage.TimeFence.weeks.tue") }}
              </template>
              <template v-if="row.weekdays.split(',').indexOf('3') != -1">
                {{ $t("DeviceManage.TimeFence.weeks.wed") }}
              </template>
              <template v-if="row.weekdays.split(',').indexOf('4') != -1">
                {{ $t("DeviceManage.TimeFence.weeks.thu") }}
              </template>
              <template v-if="row.weekdays.split(',').indexOf('5') != -1">
                {{ $t("DeviceManage.TimeFence.weeks.fri") }}
              </template>
              <template v-if="row.weekdays.split(',').indexOf('6') != -1">
                {{ $t("DeviceManage.TimeFence.weeks.sat") }}
              </template>
              <template v-if="row.weekdays.split(',').indexOf('0') != -1">
                {{ $t("DeviceManage.TimeFence.weeks.sun") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('DeviceManage.TimeFence.RegularTime')"
            width="120"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              {{ row.timeStart }}~{{ row.timeEnd }}
            </template>
          </el-table-column>
          <el-table-column
            prop="lastUpdateTime"
            :label="$t('public.ModificationTime')"
            width="140"
            show-overflow-tooltip
            sortable="custom"
          >
            <template v-slot="{ row }">
              <template v-if="row.lastUpdateTime">
                {{ row.lastUpdateTime | dateformat }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('public.Controls')"
            header-align="left"
            align="left"
          >
            <template v-slot="{ row }">
              <span class="action_icon">
                <img
                  @click="eedits(row)"
                  src="../../assets/icon_write.png"
                  alt=""
                  :title="$t('public.Editor')"
                  style="
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    cursor: pointer;
                  "
                />
              </span>
              <span class="action_icon">
                <img
                  @click="deletes(row)"
                  src="@/images/icon_del.png"
                  :title="$t('public.Delete')"
                  alt=""
                  style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                "
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page="queryObj.currentPage"
          :limit="queryObj.pageSize"
          :total="totalCount"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        />
        <ul
          class="tableCheckBox"
          style=""
          v-if="isCheckShow"
          @mouseleave="leave"
        >
          <li @click="curSelection()">当前页全选</li>
          <li @click="toggleSelection()">取消全选</li>
        </ul>
      </el-row>
    </div>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <detailItem
      :configContent="configContent"
      @submit="submit"
      v-if="show == 1"
    />
  </div>
</template>
<script>
import detailItem from "./components/comTimeFence/timeFenceItem.vue";
import Eldialog from "@/components/elDialog";
import dialogInfo from "@/components/promptMessage/index";
import naviGation from "@/components/hearder/index";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    Eldialog,
    pagination,
    naviGation,
    dialogInfo,
    detailItem,
  },
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      configContent: {},
      id: "",
      show: 0,
      searchIndex: "",
      activeName: "first",
      tableAn: [],
      tableAn1: [],
      typeInfo: "info",
      titleInfo: "",
      deviceName: "",
      dictData: [],
      multipleSelection: [],
      queryObj: {
        name: "",
        currentPage: 1,
        pageSize: 10,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      },
      totalCount: 0,
      // 遮罩全选
      isCheckShow: false,
      installFlag: false, //执行分配开关
      visibaelFlag: false, //提示消息开关
    };
  },
  computed: {
    hasSeleted() {
      return this.multipleSelection && this.multipleSelection.length;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.tableList();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    eedits(val) {
      this.configContent = val;
      this.show = 1;
    },
    sort(column) {
      console.log(column.order, column.prop, "----");
      if (column.order === "ascending") {
        this.queryObj.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.queryObj.orderRule = "DESC";
      }
      if (column.prop == "name") {
        this.queryObj.orderColume = "name";
      } else if (column.prop == "groupFullName") {
        this.queryObj.orderColume = "groupFullName";
      } else if (column.prop == "timezone") {
        this.queryObj.orderColume = "timezone";
      } else if (column.prop == "lastUpdateTime") {
        this.queryObj.orderColume = "lastUpdateTime";
      } else if (column.prop == "valid") {
        this.queryObj.orderColume = "valid";
      } else if (column.prop == "timezone") {
        this.queryObj.orderColume = "timezone";
      }
      this.tableList();
    },
    //请求列表
    async tableList() {
      const res = await this.$axios.post(
        "/httpServe/timefence/getDataInfo",
        this.queryObj,
        true
      );
      console.log(res);
      this.$nextTick(() => {
        this.dictData = res.data.content;
        this.totalCount = res.data.total;
      });
    },
    async deleteBaths(row) {
      if (row.id) {
        let param = {
          ids: [row.id],
        };
        const res = await this.$axios.post(
          "/httpServe/timefence/delete",
          param,
          true
        );
        console.log(res);
        if (res.code == 101) {
          this.$message({
            message: this.$t("DeviceManage.TimeFence.Error1"),
            type: "error",
            offset: 100,
          });
        } else {
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.searchList();
        }
      } else {
        let data = [];
        this.multipleSelection.forEach((item, index) => {
          data.push(item.id);
        });
        let param = {
          ids: data,
        };
        const res = await this.$axios.post(
          "/httpServe/timefence/delete",
          param,
          true
        );
        if (res.code == 101) {
          this.$message({
            message: this.$t("DeviceManage.TimeFence.Error1"),
            type: "error",
            offset: 100,
          });
        } else {
          if (this.multipleSelection.length > 1) {
            this.$message({
              message: this.$t("public.SuccessfullyDeleteds"),
              type: "success",
              offset: 100,
            });
            this.$refs.multipleTable.clearSelection();
            this.searchList();
          } else {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
            this.searchList();
          }
        }
      }
    },
    searchList() {
      this.queryObj.currentPage = 1;
      this.tableList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.typeInfo = "primary";
      if (this.multipleSelection.length == 0) {
        this.typeInfo = "info";
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.currentPage = page;
      this.queryObj.pageSize = limit;
      // 调用查询方法
      this.tableList();
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //所有页全选
    toggleAllSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
    //删除
    deletes(row) {
      let title = "";
      if (row.id) {
        title = this.$t("DeviceManage.TimeFence.Error2");
      } else {
        title = this.$t("DeviceManage.TimeFence.Error2") + "?";
      }
      this.$confirm(title, this.$t("public.PromptMessage"), {
        confirmButtonText: this.$t("public.Verify"),
        cancelButtonText: this.$t("public.Cancel"),
        type: "warning",
        customClass: "persdsd",
      })
        .then(() => {
          this.deleteBaths(row);
        })
        .catch(() => {
          return false;
        });
    },
    handleClose() {
      this.installFlag = false;
      this.visibaelFlag = false;
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false;
    },
    submit() {
      this.queryObj.name = "";
      this.show = 0;
      this.tableList();
      this.$message({
        message: this.$t("public.SuccessfullySaved"),
        type: "success",
        offset: 100,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  padding: 10px 10px;
}
.formBox {
  //全选弹框
  position: relative;
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 32px;
    left: 20px;
    z-index: 999;
    // background:rgba(255, 255, 255,.1)
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}
.processManage .callenia {
  height: 0;
}
.ipt {
  width: 180px;
  position: absolute;
  top: 4px;
  right: 10px;
}
::v-deep .el-link.el-link--default:hover {
  color: #606266;
}
</style>
