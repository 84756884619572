<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" style="padding: 40px 60px">
      <el-form-item :label="$t('DeviceManage.TimeFence.Name')+'：'" prop="name">
        <el-input v-model.trim="ruleForm.name" size="small" style="width: 240px" :placeholder="$t('DeviceManage.TimeFence.placeholder')" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.TimeFence.TimeZone')+'：'">
        <el-select v-model="ruleForm.timezone" size="small" style="width: 240px" placeholder="请选择时区">
          <el-option v-for="(item, index) in optionsZone" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.TimeFence.TimeFrame')+'：'">
        <el-radio-group v-model="ruleForm.valid" @change="changeEvent">
          <el-radio label="0">{{$t('DeviceManage.TimeFence.PermanentlyValid')}}</el-radio>
          <el-radio label="1">{{$t('DeviceManage.TimeFence.Custom')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" v-if="flagStatus">
        <el-date-picker :clearable="false" style="width: 130px" v-model="ruleForm.start" type="date" placeholder="请选择开始时间" size="small" value-format="yyyy-MM-dd">
        </el-date-picker>
        -
        <el-date-picker :clearable="false"
          style="width: 130px"
          v-model="ruleForm.end"
          type="date"
          placeholder="请选择结束时间"
          size="small"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.TimeFence.CycleTime')+'：'">
        <el-checkbox-group v-model="ruleForm.weekdays">
          <el-checkbox label="1" name="type">{{$t('DeviceManage.TimeFence.weeks.mon')}}</el-checkbox>
          <el-checkbox label="2" name="type">{{$t('DeviceManage.TimeFence.weeks.tue')}}</el-checkbox>
          <el-checkbox label="3" name="type">{{$t('DeviceManage.TimeFence.weeks.wed')}}</el-checkbox>
          <el-checkbox label="4" name="type">{{$t('DeviceManage.TimeFence.weeks.thu')}}</el-checkbox>
          <el-checkbox label="5" name="type">{{$t('DeviceManage.TimeFence.weeks.fri')}}</el-checkbox>
          <el-checkbox label="6" name="type">{{$t('DeviceManage.TimeFence.weeks.sat')}}</el-checkbox>
          <el-checkbox label="0" name="type">{{$t('DeviceManage.TimeFence.weeks.sun')}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.TimeFence.RegularTime')+'：'" prop="desc">
        <el-select v-model="ruleForm.timeStart" size="small" style="width: 112px" placeholder="请选择时区">
          <el-option v-for="(item, index) in optionsDay" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
        -
        <el-select v-model="ruleForm.timeEnd" size="small" style="width: 112px" placeholder="请选择时区">
          <el-option v-for="(item, index) in optionsDay" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  props: {
    configContent: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    dialogInfo
  },
  created() {
    if (this.configContent && this.configContent.id) {
      this.ruleForm.name = this.configContent.name
      this.ruleForm.timezone = this.configContent.timezone
      this.ruleForm.valid = this.configContent.valid + ''
      if (this.ruleForm.valid == 1) {
        this.changeEvent(1)
      }
      this.ruleForm.start = this.configContent.start
      this.ruleForm.end = this.configContent.end
      this.ruleForm.timeStart = this.configContent.timeStart
      this.ruleForm.timeEnd = this.configContent.timeEnd
      this.ruleForm.weekdays = this.configContent.weekdays.split(',')
    } else {
      this.getNowTime()
      this.getNowTime1()
    }
  },
  data() {
    return {
      visibaelFlag: false,
      titleInfo: '',
      ruleForm: {
        name: '',
        weekdays: [],
        timezone: 'GMT+8',
        valid: '0',
        timeStart: '00:00',
        timeEnd: '24:00',
        start: '',
        end: ''
      },
      rules: {
        name: [
          { required: true, message: this.$t('DeviceManage.TimeFence.Rules.name'), trigger: 'blur' },
          { min: 1, max: 20, message: this.$t('DeviceManage.TimeFence.Rules.NameLength'), trigger: 'blur' }
        ]
      },
      optionsDay: [
        {
          label: '00:00',
          value: '00:00'
        },
        {
          label: '00:30',
          value: '00:30'
        },
        {
          label: '01:00',
          value: '01:00'
        },
        {
          label: '01:30',
          value: '01:30'
        },
        {
          label: '02:00',
          value: '02:00'
        },
        {
          label: '02:30',
          value: '02:30'
        },
        {
          label: '03:00',
          value: '03:00'
        },
        {
          label: '03:30',
          value: '03:30'
        },
        {
          label: '04:00',
          value: '04:00'
        },
        {
          label: '04:30',
          value: '04:30'
        },
        {
          label: '05:00',
          value: '05:00'
        },
        {
          label: '05:30',
          value: '05:30'
        },
        {
          label: '06:00',
          value: '06:00'
        },
        {
          label: '06:30',
          value: '06:30'
        },
        {
          label: '07:00',
          value: '07:00'
        },
        {
          label: '07:30',
          value: '07:30'
        },
        {
          label: '08:00',
          value: '08:00'
        },
        {
          label: '08:30',
          value: '08:30'
        },
        {
          label: '09:00',
          value: '09:00'
        },
        {
          label: '09:30',
          value: '09:30'
        },
        {
          label: '10:00',
          value: '10:00'
        },
        {
          label: '10:30',
          value: '10:30'
        },
        {
          label: '11:00',
          value: '11:00'
        },
        {
          label: '11:30',
          value: '11:30'
        },
        {
          label: '12:00',
          value: '12:00'
        },
        {
          label: '12:30',
          value: '12:30'
        },
        {
          label: '13:00',
          value: '13:00'
        },
        {
          label: '13:30',
          value: '13:30'
        },
        {
          label: '14:00',
          value: '14:00'
        },
        {
          label: '14:30',
          value: '14:30'
        },
        {
          label: '15:00',
          value: '15:00'
        },
        {
          label: '15:30',
          value: '15:30'
        },
        {
          label: '16:00',
          value: '16:00'
        },
        {
          label: '16:30',
          value: '16:30'
        },
        {
          label: '17:00',
          value: '17:00'
        },
        {
          label: '17:30',
          value: '17:30'
        },
        {
          label: '18:00',
          value: '18:00'
        },
        {
          label: '18:30',
          value: '18:30'
        },
        {
          label: '19:00',
          value: '19:00'
        },
        {
          label: '19:30',
          value: '19:30'
        },
        {
          label: '20:00',
          value: '20:00'
        },
        {
          label: '20:30',
          value: '20:30'
        },
        {
          label: '21:00',
          value: '21:00'
        },
        {
          label: '21:30',
          value: '21:30'
        },
        {
          label: '22:00',
          value: '22:00'
        },
        {
          label: '22:30',
          value: '22:30'
        },
        {
          label: '23:00',
          value: '23:00'
        },
        {
          label: '23:30',
          value: '23:30'
        },
        {
          label: '24:00',
          value: '24:00'
        }
      ],
      optionsZone: [
        {
          label: `GMT -12 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-12'
        },
        {
          label: `GMT -11 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-11'
        },
        {
          label: `GMT -10 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-10'
        },
        {
          label: `GMT -9 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-9'
        },
        {
          label: `GMT -8 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-8'
        },
        {
          label: `GMT -7 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-7'
        },
        {
          label: `GMT -6 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-6'
        },
        {
          label: `GMT -5 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-5'
        },
        {
          label: `GMT -4 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-4'
        },
        {
          label: `GMT -3 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-3'
        },
        {
          label: `GMT -2 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-2'
        },
        {
          label: `GMT -1 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT-1'
        },
        {
          label: `GMT +0 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+0'
        },
        {
          label: `GMT +1 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+1'
        },
        {
          label: `GMT +2 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+2'
        },
        {
          label: `GMT +3 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+3'
        },
        {
          label: `GMT +4 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+4'
        },
        {
          label: `GMT +5 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+5'
        },
        {
          label: `GMT +6 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+6'
        },
        {
          label: `GMT +7 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+7'
        },
        {
          label: `GMT +8 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+8'
        },
        {
          label: `GMT +9 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+9'
        },
        {
          label: `GMT +10 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+10'
        },
        {
          label: `GMT +11 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+11'
        },
        {
          label: `GMT +12 ${this.$t('DeviceManage.TimeFence.Hour')}`,
          value: 'GMT+12'
        }
      ],
      flagStatus: false
    }
  },
  methods: {
    getNowTime() {
      var now = new Date()
      var year = now.getFullYear() //得到年份
      var month = now.getMonth() //得到月份
      var date = now.getDate() //得到日期
      month = month + 1
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      this.$set(this.ruleForm, 'start', defaultDate)
    },
    getNowTime1() {
      var now = new Date()
      var year = now.getFullYear() //得到年份
      var month = now.getMonth() //得到月份
      var date = now.getDate() //得到日期
      month = month + 2
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      this.$set(this.ruleForm, 'end', defaultDate)
    },
    changeEvent(val) {
      if (val == 1) {
        this.flagStatus = true
      } else {
        this.flagStatus = false
      }
    },
    async save() {
      console.log(new Date(this.ruleForm.start).getTime(), '123')
      console.log(new Date(this.ruleForm.end).getTime(), '123')
      if (
        new Date(this.ruleForm.start).getTime() >
        new Date(this.ruleForm.end).getTime()
      ) {
        this.titleInfo = this.$t('DeviceManage.TimeFence.CheckDel1')
        this.visibaelFlag = true
        return false
      }
      if (this.ruleForm.timeStart > this.ruleForm.timeEnd) {
        this.titleInfo = this.$t('DeviceManage.TimeFence.CheckDel2')
        this.visibaelFlag = true
        return false
      }
      //console.log(this.ruleForm.start, "111");
      if (this.ruleForm.start == null || this.ruleForm.end == null) {
        this.titleInfo = this.$t('DeviceManage.TimeFence.CheckDel3')
        this.visibaelFlag = true;
        return false;
      }
      if (this.configContent.id) {
        console.log(1)
        let params = {
          ids: [this.configContent.id],
          name: this.ruleForm.name,
          timezone: this.ruleForm.timezone,
          valid: this.ruleForm.valid,
          start: this.ruleForm.start,
          end: this.ruleForm.end,
          weekdays: this.ruleForm.weekdays.join(','),
          timeStart: this.ruleForm.timeStart,
          timeEnd: this.ruleForm.timeEnd
        }
        console.log(params)
        const res = await this.$axios.post(
          '/httpServe/timefence/save',
          params,
          true
        )
        if (res.code == 101) {
          this.titleInfo = this.$t('DeviceManage.TimeFence.CheckDel4')
          this.visibaelFlag = true
        } else {
          this.$emit('submit')
        }
      } else {
        let params = {
          name: this.ruleForm.name,
          timezone: this.ruleForm.timezone,
          valid: this.ruleForm.valid,
          start: this.ruleForm.start,
          end: this.ruleForm.end,
          weekdays: this.ruleForm.weekdays.join(','),
          timeStart: this.ruleForm.timeStart,
          timeEnd: this.ruleForm.timeEnd
        }
        console.log(params)
        const res = await this.$axios.post(
          '/httpServe/timefence/save',
          params,
          true
        )
        // console.log(res, '时间围栏')
        if (res.code == 101) {
          this.titleInfo = this.$t('DeviceManage.TimeFence.CheckDel4')
          this.visibaelFlag = true
        } else {
          this.$emit('submit')
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 14px !important;
}
</style>
